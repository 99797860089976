<script setup>
import { ref, computed, onMounted } from "vue"
import { useStore } from 'vuex'
import { mdiCheck, mdiShieldCheck, mdiSpeedometer, mdiStar, mdiInformation } from '@mdi/js'
import Icon from '@/components/Icon.vue'
import JbButton from '@/components/JbButton.vue'
import GLOBAL_URLS from "@/globals/stripe"

const store = useStore()
const userId = computed(() => store.state.auth.user.user.id)
const getClientReferenceId = computed(() => (window.Rewardful && window.Rewardful.referral || ('checkout_' + (new Date).getTime())))
const spinnerDisplayed = ref(false)

const plans = computed(() => {
    const products = store.state.products.deals || []
    return products
        .filter(product => product.plan_id_stripe)
        .map(product => ({
            name: product.name,
            price: product.price,
            productId: product.id,
            priceId: product.plan_id_stripe,
            tokens: product.tokens,
            bonusTokens: product.tokens * 0.1,
            popular: isPopular(product.tokens),
            popularLabel: getPopularLabel(product.tokens),
            labelColor: getLabelColor(product.tokens)
        }))
})

const isPopular = (tokens) => [53, 320, 1100].includes(tokens)

const getPopularLabel = (tokens) => {
    switch (tokens) {
        case 53: return "Most popular"
        case 320: return "Best value"
        case 1100: return "Power user"
        default: return ""
    }
}

const getLabelColor = (tokens) => {
    switch (tokens) {
        case 53: return "from-blue-600 to-blue-400"
        case 320: return "from-purple-600 to-purple-400"
        case 1100: return "from-green-600 to-green-400"
        default: return ""
    }
}

const subscribe = (productId, priceId) => {
    spinnerDisplayed.value = true
    const url = `${GLOBAL_URLS.STRIPE_URL}/create-subscription-session?plan=${priceId}&user_id=${userId.value}&product_id=${productId}&reference=${getClientReferenceId.value}`
    window.location.href = url
}

onMounted(() => {
    store.dispatch("products/getTokenProducts")
})
</script>

<template>
    <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <!-- Header -->
        <div class="text-center mb-12">
            <h1 class="text-4xl font-bold bg-gradient-to-r from-blue-600 to-blue-400 bg-clip-text text-transparent">
                Choose Your Plan
            </h1>
            <p class="mt-4 text-gray-600 dark:text-gray-400 max-w-2xl mx-auto">
                Get more value with our subscription plans. Save up to 10% compared to pay-as-you-go pricing.
            </p>
        </div>

        <!-- Warning Banner -->
        <div
            class="mb-6 p-4 rounded-lg border border-yellow-200 bg-yellow-50 dark:bg-gray-800/50 dark:border-yellow-700">
            <div class="flex items-start">
                <Icon :path="mdiInformation" class="w-5 h-5 text-yellow-500 mt-0.5" />
                <p class="ml-3 text-sm text-gray-700 dark:text-gray-800">
                    <span class="font-medium">Important:</span> Monthly allocations add to your existing balance. Plan
                    changes require cancellation of current subscription before switching.
                </p>
            </div>
        </div>

        <!-- Plans Grid -->
        <div class="grid md:grid-cols-2 lg:grid-cols-4 gap-6 mb-6">
            <div v-for="plan in plans" :key="plan.name"
                class="relative bg-white dark:bg-gray-800 rounded-xl shadow-xl transform transition-all duration-300 hover:scale-102"
                :class="{ 'ring-2 ring-blue-500 dark:ring-blue-400': plan.popular }">

                <!-- Popular Badge -->
                <div v-if="plan.popular"
                    class="absolute -top-3 left-1/2 -translate-x-1/2 bg-gradient-to-r px-4 py-1 rounded-full text-white text-sm font-bold shadow-lg animate-pulse"
                    :class="plan.labelColor">
                    {{ plan.popularLabel }}
                </div>

                <div class="p-6">
                    <!-- Plan Header -->
                    <div class="text-center mb-8">
                        <h3 class="text-2xl font-bold mb-2"
                            :class="plan.popular ? 'text-blue-600 dark:text-blue-400' : 'text-gray-900 dark:text-white'">
                            {{ plan.name }}
                        </h3>
                        <div class="flex items-baseline justify-center">
                            <span class="text-4xl font-extrabold"
                                :class="plan.popular ? 'text-blue-600 dark:text-blue-400' : ''">
                                €{{ plan.price }}
                            </span>
                            <span class="ml-2 text-gray-500 dark:text-gray-400">/month</span>
                        </div>
                    </div>

                    <!-- Features List -->
                    <ul class="space-y-4 mb-8">
                        <li class="flex items-start space-x-3">
                            <Icon :path="mdiStar" class="w-5 h-5 text-blue-500 flex-shrink-0 mt-1" />
                            <div>
                                <span class="font-semibold">{{ plan.tokens + plan.bonusTokens }} tokens per month</span>
                                <div class="text-sm text-gray-600 dark:text-gray-400">
                                    ({{ plan.tokens }} + <span class="text-green-600 dark:text-green-400">{{
                                        plan.bonusTokens }} bonus</span>)
                                </div>
                            </div>
                        </li>
                    </ul>

                    <!-- Subscribe Button -->
                    <jb-button :spinner="spinnerDisplayed" @click="subscribe(plan.productId, plan.priceId)"
                        class="w-full transform transition-all duration-300 hover:shadow-lg hover:-translate-y-1"
                        :class="plan.popular ? 'animate-shimmer bg-gradient-to-r from-blue-500 via-blue-600 to-blue-500 bg-[length:200%_100%]' : 'hover:bg-gray-50 dark:hover:bg-gray-700'"
                        :color="plan.popular ? 'info' : 'white'" :label="`Get started with ${plan.name}`" />
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
@keyframes shimmer {
    0% {
        background-position: 100% 0;
    }

    100% {
        background-position: -100% 0;
    }
}

.animate-shimmer {
    animation: shimmer 3s infinite linear;
}

@keyframes gentle-pulse {

    0%,
    100% {
        opacity: 0.9;
    }

    50% {
        opacity: 1;
    }
}

.animate-pulse {
    animation: gentle-pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}
</style>